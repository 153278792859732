<template>
  <router-view />
</template>
<script>
  import Theme from '@/utils/theme'
  export default {
    mounted() {
      Theme.change('#008080')
    }
  }
</script>