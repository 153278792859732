const data = {
  deviceStatus: ['', '运行', '空闲','维修', '故障'],
  holidays: ['班', '休'],
  deviceType: ['', '外因故障', '内因故障'],
  deviceFalg: ['', '紧急故障', '一般故障', '轻微故障'],
  deviceHandle: ['', '已处理', '未处理'],
  qualityType: ['', '尺寸', '外观', '重量', '性能', '成分'],
  templateType: ['', '自检', '首检', '末检', '巡检', '成品检验',],
  groupPlanType: ['', '白班', '夜班', '两班倒', '三班倒', '自定义'],
  statuses: ['', '待执行', '', '', '', '', '进行中', '已完成', '拒审核', '已作废'],
  taskMaterialStatus: ['已申请', '已领料', '已驳回',],
  logMethods: ['', '增加', '编辑', '删除', '', '', '', '', '', '', '', '登录帐号', '修改密码', ''],
  logScenes: {
    'admin': '管理员',
    'admin_goup': '管理角色',
    'admin_menu': '管理权限',
    'task': '任务'
  },
  requestModel: [ 
    { "cid": 7, "lng": 102, "alt": 0, "lat": 13, "title": "剪板机#01", "rotateY": 1, "name": "剪板机" , status:'1'},
    { "cid": 7, "lng": 102, "alt": 0, "lat": 9, "title": "剪板机#02", "rotateY": 1, "name": "剪板机", status:'1' },
    { "cid": 10, "lng": 102, "alt": 0, "lat": 17, "title": "折弯机#01", "rotateY": 1, "name": "折弯机", status:'2' },
    { "cid": 10, "lng": 102, "alt": 0, "lat": 25, "title": "折弯机#02", "rotateY": 1, "name": "折弯机", status:'2' },
    { "cid": 3, "lng": 52, "alt": 0, "lat": 22, "title": "打胶机#01", "rotateY": 0, "name": "打胶机" , status:'1'},
    { "cid": 4, "lng": 52, "alt": 0, "lat": 28, "title": "打印机#01", "rotateY": 1, "name": "打印机" , status:'1'},
     { "cid": 18, "lng": 86, "alt": 0, "lat": 10, "title": "开卷校平机#01", "rotateY": -1, "name": "开卷校平机" , status:'3'},
     { "cid": 6, "lng": 86, "alt": 0, "lat": 28, "title": "激光切割机#03", "rotateY": 0, "name": "激光切割机", status:'1' },
     { "cid": 15, "lng": 60, "alt": 0, "lat": -42, "title": "桥架槽体自动成型机#01", "rotateY": 1, "name": "桥架槽体自动成型机", status:'4' },
     { "cid": 33, "lng": 60, "alt": 0, "lat": -62, "title": "广告牌1", "rotateY": 1, "name": "广告牌1", status:'4' },
     { "cid": 6, "lng": 86, "alt": 0, "lat": -39, "title": "激光切割机#01", "rotateY": 1, "name": "激光切割机", status:'1' },
     { "cid": 6, "lng": 86, "alt": 0, "lat": -23, "title": "激光切割机#02", "rotateY": 1, "name": "激光切割机", status:'1' },
    { "cid": 9, "lng": 102, "alt": 0, "lat": -14, "title": "柔性折弯中心#01", "rotateY": 1, "name": "柔性折弯中心" , status:'1'},
    { "cid": 9, "lng": 102, "alt": 0, "lat": -27, "title": "柔性折弯中心#02", "rotateY": 1, "name": "柔性折弯中心", status:'1' }, 
    { "cid": 7, "lng": 102, "alt": 0, "lat": -64, "title": "折弯机2#01", "rotateY": 1, "name": "折弯机2" , status:'2'},
    { "cid": 7, "lng": 102, "alt": 0, "lat": -53, "title": "折弯机2#02", "rotateY": 1, "name": "折弯机2", status:'4' }, 
    { "cid": 14, "lng": 86, "alt": 0, "lat": -11, "title": "折弯机3#01", "rotateY": -1, "name": "折弯机3" , status:'1'},
    { "cid": 12, "lng": 37, "alt": 0, "lat": -41, "title": "喷塑流水线#01", "rotateY": 0, "name": "喷塑流水线" , status:'2'},
    { "cid": 33, "lng": 46, "alt": 0, "lat": -38, "title": "广告牌6", "rotateY": 1, "name": "广告牌6", status:'1' }, 
    { "cid": 33, "lng": 46, "alt": 0, "lat": -35, "title": "广告牌7", "rotateY": 1, "name": "广告牌7", status:'1' }, 
    { "cid": 12, "lng": 37, "alt": 0, "lat": -44, "title": "倒角裁剪机#01", "rotateY": 0, "name": "倒角裁剪机" , status:'2'},
    { "cid": 13, "lng": 102, "alt": 0, "lat": -10, "title": "转塔冲床#01", "rotateY": 1, "name": "转塔冲床" , status:'1'}, 
    { "cid": 16, "lng": 75, "alt": 0, "lat": -43, "title": "箱体自动成型机#01", "rotateY": 1, "name": "箱体自动成型机" , status:'1'},
    { "cid": 33, "lng": 76, "alt": 0, "lat": -68, "title": "广告牌2", "rotateY": 1, "name": "广告牌2" , status:'1'},
    { "cid": 17, "lng": 86, "alt": 0, "lat": -61, "title": "全自动激光开卷柔性生产线#01", "rotateY": 1, "name": "全自动激光开卷柔性生产线", status:'1' }, 
    { "cid": 33, "lng": 108, "alt": 0, "lat": 65, "title": "广告牌4", "rotateY": -2, "name": "广告牌2" , status:'1'},
    { "cid": 33, "lng": 108, "alt": 0, "lat": 68, "title": "广告牌5", "rotateY": 1, "name": "广告牌2" , status:'1'},
    { "cid": 33, "lng": 108, "alt": 0, "lat": 71, "title": "广告牌8", "rotateY": 1, "name": "广告牌2" , status:'1'},

    // { "cid": 19, "lng": 104, "alt": 0, "lat": -58, "title": "机器人ER170#01", "rotateY": 1, "name": "机器人ER170", status:'1' },
    // { "cid": 19, "lng": 104, "alt": 0, "lat": -30, "title": "机器人ER170#02", "rotateY": 1, "name": "机器人ER170" , status:'4'},
    // { "cid": 19, "lng": 104, "alt": 0, "lat": -13, "title": "机器人ER170#03", "rotateY": 1, "name": "机器人ER170" , status:'1'},
    // { "cid": 19, "lng": 86, "alt": 0, "lat": -50, "title": "机器人ER170#04", "rotateY": 1, "name": "机器人ER170" , status:'1'},
    // { "cid": 19, "lng": 86, "alt": 0, "lat": -43, "title": "机器人ER170#05", "rotateY": 1, "name": "机器人ER170", status:'3' },
    // { "cid": 19, "lng": 86, "alt": 0, "lat": -6, "title": "机器人ER170#06", "rotateY": 1, "name": "机器人ER170" , status:'1'},
    
    // { "cid": 19, "lng": 86, "alt": 1, "lat": -6, "title": "测试动画#06", "rotateY": 1, "name": "测试动画" , status:'1'},

    
    { "cid": 0, "lng": 102, "alt": 0, "lat": 30, "title": "冲床1#01", "name": "冲床1", "rotateY": 1, status:'4' },
    { "cid": 0, "lng": 102, "alt": 0, "lat": 34, "title": "冲床2#01", "name": "冲床1", "rotateY": -1, status:'1' },
    { "cid": 1, "lng": 102, "alt": 0, "lat": 40, "title": "冲床2#01", "name": "冲床2", "rotateY": -1 , status:'1'},
    { "cid": 2, "lng": 102, "alt": 0, "lat": -40, "title": "冲床3#01", "rotateY": -2, "name": "冲床3" , status:'1'}, 
    { "cid": 8, "lng": -96, "alt": 0, "lat": -36, "title": "立体库#01", "rotateY": 0, "name": "立体库" },

    {
      "cid": 20, "title": "器械箱1#01", "name": "器械箱1",
      "children": [
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": -39, "alt": 0, "lat": 3 },

        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": -33, "alt": 0, "lat": 3 },

        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": -86, "alt": 0, "lat": 3 },

        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": -100, "alt": 0, "lat": 3 },

        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": -32, "alt": 0, "lat": -8 },

        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": -42, "alt": 0, "lat": -8 },

        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": -54, "alt": 0, "lat": -8 },


        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": -58.5, "alt": 0, "lat": -8 },
      ]
    },
    {
      "cid": 21, "title": "器械箱2#01", "name": "器械箱2",
      "children": [
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": 3 },

        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": -15, "alt": 0, "lat": 3 },

        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 73 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 69 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 65 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 61 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 57 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 53 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 49 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 45 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 41 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 37 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 33 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 29 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 25 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 21 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 17 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 13 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 8 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": 3 },


        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": 6, "alt": 0, "lat": -8 },

        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": -8, "alt": 0, "lat": -8 },

        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -69 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -65 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -61 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -56 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -52 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -48 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -44 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -40 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -36 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -32 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -28 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -24 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -21 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -17 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -13 },
        { "rotateY": 2, "lng": -18, "alt": 0, "lat": -8 },

      ]
    },
    {
      "cid": 21, "title": "工作台", "name": "工作台",
      "children": [

        { "cid": 17, "lng": -54, "alt": 0, "lat": 8, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -54, "alt": 0, "lat": 18, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -54, "alt": 0, "lat": 28, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -54, "alt": 0, "lat": 38, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -54, "alt": 0, "lat": 48, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -54, "alt": 0, "lat": 58, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -54, "alt": 0, "lat": 68, "title": "工作台", "rotateY": 1, "name": "工作台" },

        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 8, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 18, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 28, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 38, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 48, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 58, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": 68, "title": "工作台", "rotateY": 1, "name": "工作台" },

        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -8, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -18, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -28, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -38, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -48, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -58, "title": "工作台", "rotateY": 1, "name": "工作台" },
        { "cid": 17, "lng": -68.5, "alt": 0, "lat": -68, "title": "工作台", "rotateY": 1, "name": "工作台" },

      ]
    }
  ]


}
export default {
  data
}