// 小时分秒补零
const addZeor = function(num) {
  return num < 10 ? '0' + num : num
}

// 时间戳转日期
const timeTodate = function(timestamp, format) {
  var formateArr = ['Y', 'm', 'd', 'H', 'i', 's']
  var returnArr = []

  if (parseInt(timestamp) === 0 || isNaN(parseInt(timestamp))) {
    return '-'
  }

  var date = new Date(timestamp < 1000000000000 ? timestamp * 1000 : timestamp)

  returnArr.push(date.getFullYear())

  var month = date.getMonth() + 1
  month = month < 10 ? '0' + month : month
  returnArr.push(month)

  var day = date.getDate()
  day = day < 10 ? '0' + day : day
  returnArr.push(day)

  var hours = date.getHours()
  hours = hours < 10 ? '0' + hours : hours
  returnArr.push(hours)

  var minute = date.getMinutes()
  minute = minute < 10 ? '0' + minute : minute
  returnArr.push(minute)

  var second = date.getSeconds()
  second = second < 10 ? '0' + second : second
  returnArr.push(second)

  returnArr.forEach(function(v, i) {
    format = format.replace(formateArr[i], returnArr[i])
  })
  return format
}

const timeToHour = function(seconds) {
  if (seconds >= 3600) {
    return Math.ceil(seconds * 100 / 3600) / 100 + '小时'
  } else if (seconds >= 60) {
    return Math.ceil(seconds * 100 / 60) / 100 + '分钟'
  } else {
    return seconds + '秒'
  }

}

const mobileEncryption = function(mobile) {
  let mobileStr = mobile.toString()
  return mobileStr.substring(0, 3) + '****' + mobileStr.substring(8, 11)
}

module.exports = {
  mobileEncryption,
  timeToHour,
  timeTodate,
  addZeor
}